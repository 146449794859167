import moment from "moment";
import enloc from 'moment/locale/en-gb';
import ptloc from 'moment/locale/pt';
import frloc from 'moment/locale/fr';
import { useRef } from "react";


function useMomentLocaleHook(specificLocaleConfigurations){
  
    const selectedLang = useRef(null);
    const localeConf = useRef(null);

    const getProperLocaleConf = (selectedLocale) => {
        let confLocale = selectedLocale;
        if(specificLocaleConfigurations){
            confLocale = {...selectedLocale,...specificLocaleConfigurations};

            return confLocale;
        }

        return confLocale;
    }

    
   
    
    if(navigator.language.startsWith("pt")){
        moment.updateLocale('pt',getProperLocaleConf(ptloc));
        selectedLang.current = "pt";
        localeConf.current = ptloc;
    }else if(navigator.language.startsWith("fr")){
        moment.updateLocale('fr',getProperLocaleConf(frloc));
        selectedLang.current = "fr";
        localeConf.current = ptloc;
    }else{
        moment.updateLocale('en',getProperLocaleConf(enloc));
        selectedLang.current = "en";
        localeConf.current = ptloc;
    }

    return {selectedLang,localeConf};
}

export default useMomentLocaleHook;