
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import useMomentLocaleHook from "../../../barrel/hooks/useMomentLocaleHook/useMomentLocaleHook";
import moment from "moment";
import { TextField } from '@mui/material';
dayjs.extend(utc);

//updated version
const HxfDatePicker = (props) => {

    useMomentLocaleHook();

    const getProperValue = () => {
        let val = props?.value ? (moment(props.value)) : null;
        return val;
    }
    return (
        <LocalizationProvider  dateAdapter={AdapterMoment}>
        <DatePicker
            {...props?.customProps}
            minDate={dayjs(new Date(new Date().setFullYear(new Date().getFullYear() - 20)))} //minimum date is current - 20 years, very old dates like 1901 have timezone issues
            openTo="day"
            views={['year', 'month', 'day']}
            label={props.label}
            value={getProperValue()}
            onChange={(newValue) => {
                
                
                if(props?.isLegacy){
                    let res = new Date(newValue);
                    props.onChange({"_d":res}); //_d to support migration prev material ui v4, eventually remove _d and replace it in all componeents using _d
                }else{
                    if(newValue !== "" && newValue !== null){
                        let res = new Date(newValue);
                        props.onChange(res);
                    }else{
                       
                        props.onChange(null);
                    }
        
                }
             
            }}
            slotProps={{ 
                textField: { 
                  sx: { width: props?.fullWidth ? '100%' : null }, 
                  error: props.error, 
                  helperText: null
                }
              }}
         
            format="DD/MM/YYYY" //new version instead of inputFormat
            />
        </LocalizationProvider>
    )
    /*return ( 
        <DatePicker
            disabled={props?.disabled}
            okLabel={"OK"}
            cancelLabel={"Cancel"}
            showTodayButton={false}
            format={DatesConversionService().getDatePickerInputFormat()}
                label={props.label}
                inputVariant={props.inputVariant}
                value={props.value}
                onChange={(evt) =>{
                    props.onChange(evt);
                }}
                error={props.error}
                className={props.className}
            />
    )*/
}

export default HxfDatePicker;