import createStore from "../../../../../../AppCustomStateManagement/createStore";

// Library interfaces
interface IActionScreenProduceQuantityProduct {
  quantity: any;
  Id_WarehouseLocation: number;
  Id_Warehouse: number;
  Id_Lot: number;
  temp_WarehouseLocation_label?: string;
  temp_Warehouse_label?: string;
  temp_Lot_label?: string;
  temp_Lot_code?: string;
  expirityDate?: any;
}

interface IActionScreenProduceQuantityAction {
  type: any;
  payload?: any;
}

const initialState = {
  quantity: "",
  Id_WarehouseLocation: 0,
  Id_Warehouse: 0,
  Id_Lot: 0,
  temp_WarehouseLocation_label: "",
  temp_Warehouse_label: "",
  temp_Lot_label: "",
  temp_Lot_code: "",
  expirityDate:null
};

const reducer = (
  store: IActionScreenProduceQuantityProduct,
  action: IActionScreenProduceQuantityAction
) => {
  switch (action.type) {
    case "RESET_STORE":
      store = { ...initialState };
      return store;
    case "SET_STORE":
      store = { ...action.payload };
      return store;
    case "SET_QUANTITY":
      store = { ...store, quantity: action.payload };
      return store;
    case "SET_WAREHOUSE_LOCATION":
      store = {
        ...store,
        Id_WarehouseLocation: action.payload.Id_WarehouseLocation,
        temp_WarehouseLocation_label: action.payload.label,
      };
      return store;
    case "SET_WAREHOUSE":
      store = {
        ...store,
        Id_Warehouse: action.payload.Id_Warehouse,
        temp_Warehouse_label: action.payload.label,
      };
      return store;
    case "SET_LOT":
      store = {
        ...store,
        Id_Lot: action.payload.Id_Lot,
        temp_Lot_label: action.payload.label,
        temp_Lot_code: action.payload.code,
      };
      return store;
    case "SET_EXPIRITY_DATE":
        store = {
          ...store,
          expirityDate: action.payload.expirityDate,
        };
        return store;
    case "RESET_WAREHOUSE":
      store = { ...store, Id_Warehouse: 0 };
      return store;
    case "RESET_LOT":
      store = { ...store, Id_Lot: 0 };
      return store;
    case "RESET_WAREHOUSE_LOCATION":
      store = { ...store, Id_WarehouseLocation: 0 };
      return store;
    default:
      return store;
  }
};

export const [
  useActionScreenProduceQuantityProductStore,
  dispatchActionScreenProduceQuantityProductStore,
] = createStore(initialState, reducer);
